<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/overhaulTask' }">检修计划</el-breadcrumb-item>
          <el-breadcrumb-item>编辑计划</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title">
            基本信息
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="检修代号" prop="name">
                <el-input v-model="form.name" placeholder="请输入检修代号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="来源：">
                  <el-input v-model="form.source" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-device-info">
            <div class="form-title">设备信息</div>
            <div style="padding-left: 40px" v-show="form.source=='新创建'">
              <el-button @click="delDevice" type="text" style="color: red;" v-if="!isDelDevice">删除设备</el-button>
              <el-button @click="addDevice" type="text" v-else>添加设备</el-button>
            </div>
          </div>
          <div style="margin-bottom:20px;" v-if="form.aliasObj.alias">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设备别名：">{{form.aliasObj.alias}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备识别号：">{{form.aliasObj.ein ? form.aliasObj.ein : '无'}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设施位置：">{{form.aliasObj.facilityName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="巡视点名称：">{{form.aliasObj.patrolName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设备名称：">{{form.aliasObj.deviceName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备类型：">{{form.aliasObj.deviceTypeName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="所属部门：">{{form.aliasObj.departmentName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="规格参数：">{{form.aliasObj.specification}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="投入使用日期：" label-width="130px">
                  {{ form.aliasObj.applyTime ? dayjs(form.aliasObj.applyTime).format('YYYY-MM-DD'):'' }}
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <div v-if="cForm.baseInfo.source != 0">
            <div class="form-title">问题文字描述</div>
            <el-form-item label-width="20px">{{cForm.abnormalTaskInfo.describeContent}}</el-form-item>
            <div class="form-title">巡检工作图片</div>
            <div class="img-box">
              <el-image 
                class="images"
                v-for="(item,index) in cForm.abnormalTaskInfo.fileUrls"
                :key="index"
                :src="item"
                :preview-src-list="[item]">
              </el-image>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="巡检人：">{{cForm.abnormalTaskInfo.submitter ? cForm.abnormalTaskInfo.submitter : '无'}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="提交时间：">
                    {{ cForm.abnormalTaskInfo.completeTime ? dayjs(cForm.abnormalTaskInfo.completeTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <div class="form-title">
            检修人员配置<span style="color: red"> *</span>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="检修人员">
                <div @click="addStaff()" class="staffBox">
                  <div v-if="staffList.length>0">
                    <el-tag v-for="staff in staffList" :key="staff.id" style="margin-right: 4px;">
                      {{ staff.name }}
                    </el-tag>
                  </div>
                  <div v-else style="color: #909399;text-align: center;">请选择检修人员</div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">
            问题文字描述<span style="color: red"> *</span>
          </div>
          <el-form-item label-width="20px" prop="desc">
            <el-input v-model="form.desc" type="textarea" :rows="2" style="width:700px" placeholder="请输入问题描述"></el-input>
          </el-form-item>
          <div class="form-title">
            检修方案（审核人员填）<span style="color: red"> *</span>
          </div>
          <el-form-item label-width="20px" prop="plan">
            <el-input v-model="form.plan" type="textarea" :rows="2" style="width:700px" placeholder="请输入检修方案"></el-input>
          </el-form-item>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/overhaulTask')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <el-drawer
        title="添加设备"
        :with-header="false"
        :visible.sync="deviceDrawer"
        direction="rtl"
        size="1200px">
        <div class="drawer-title">添加设备</div>
        <div class="drawer-content table-tree">
          <div class="tree">
            <div class="tree-title">设施位置名称</div>
            <el-tree
              :data="facility"
              node-key="id"
              @node-click="facilityClick"
              :props="defaultProps"
              :default-checked-keys="[1]"
              highlight-current
              ref="tree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="tree">
            <div class="tree-title">巡视点名称</div>
            <el-tree
              :data="patrolList"
              node-key="id"
              @node-click="patrolClick"
              :props="defaultPatProps"
              :default-checked-keys="[1]"
              highlight-current
              ref="tree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="table">
            <el-table :data="tableDataCp" height="700">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-radio-group v-model="deviceRadio" @change="checkeChange(scope.row)">
                    <el-radio :label="scope.row.patrolDeviceId">{{''}}</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column prop="alias" label="设备别名"></el-table-column>
              <el-table-column prop="materialsCode" label="设备识别号"></el-table-column>
              <el-table-column prop="deviceName" label="设备名称"></el-table-column>
              <el-table-column prop="specification" label="规格参数"></el-table-column>
              <el-table-column prop="deviceTypeName" label="设备类型"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="deviceDrawer=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="deviceDrawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
      <el-drawer
        title=""
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
      size="900px">
        <div class="drawer-title">选择检修员</div>
        <div class="drawer-content" v-if="drawer">
          <div class="table-tree">
            <div class="tree">
              <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
              <el-tree
                :data="treeData"
                @node-click="treeRowClick"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                ref="tree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            
            <div class="table">
              <el-table :data="tableData" style="width: 100%" height="700">
                <el-table-column width="40">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sex" label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
                </el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="name" label="岗位">
                  <template slot-scope="scope">
                    <div
                      v-for="item in scope.row.positionInfoList"
                      :key="item.positionId"
                    >{{item.positionName}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawer=false">取消</el-button>
          <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      loading: false,
      isDelDevice: false,
      form: {
        name: '',
        source: '新创建',
        aliasObj: {},
        desc: '',
        plan: '',
        eid: 0
      },
      cForm: {
        abnormalTaskInfo: {},
        baseInfo: {},
        elementInfos: {},
        patrolDeviceInfo: {}
      },
      row: {},
      deviceRadio: '',
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        desc:[{ required: true, message: '必填项', trigger: 'blur' }],
        plan:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      props: {
        value: 'id',
        label: 'name',
        children: 'childTree'
      },
      options: [],
      value: [],
      facility: [], // 设施列表
      patrolList: [],
      staffList: [], // 检修人员列表
      index: 1,
      // 添加设备初始变量
      deviceDrawer: false,
      tableDataCp: [],
      tableSelectList: {},
      deviceTableData: [],
      facilityId: 0, // 设施Id
      facilityName: '', // 设施位置
      patrolId: 0, // 巡视点Id
      patrolName: '', // 巡视点名称
      patrolNumber: '', // 巡视点编号
      // 增加配置初始变量
      drawer: false,
      treeData: [],
      tableData: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      defaultPatProps: {
        children: 'childTree',
        label: 'patrolName'
      },
      checkedStaffList: [],
      staffListID: [],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
      deleteDeviceId: null,
      deleteReconditionStaffIds: []
    }
  },
  methods: {
    dayjs,
    // 添加设备
    addDevice() {
      this.deviceDrawer = true;
      this.tableDataCp = []
      this.deviceTableData.forEach(item => {
        let d = JSON.parse(JSON.stringify(item));
        d.checked = false
        this.tableDataCp.push(d)
      })
    },
    // 删除设备
    delDevice() {
      this.deleteDeviceId = this.form.aliasObj.reconditionDeviceId
      this.form.aliasObj = {}
      this.isDelDevice = true
    },
    // 勾选设备
    checkeChange(row) {
      this.deviceRadio = row.patrolDeviceId
      this.tableSelectList = row // 勾选
    },
    // 选择设备点击确定
    deviceDrawerOK(){
      if(!this.tableSelectList.patrolDeviceId){
        this.$message.error('请选择设备！')
        return
      }
      this.$ajax.post("rDeviceDetail", { patrolDeviceId: this.tableSelectList.patrolDeviceId }).then((res) => {
        this.form.aliasObj = res.data
      });
      this.deviceDrawer = false;
      this.isDelDevice = false
    },
    // 获取设施名称
    load_facility() {
      this.$ajax.get("facilityQueryAll").then((res) => {
        this.facility = res.data;
        this.facilityId = res.data[0].id
        this.facilityName = res.data[0].name
        this.loadPatrolList(this.facilityId)
      });
    },
    // 获取巡视点名称
    loadPatrolList(id) {
      this.$ajax.post("patrolList", {
        facilityId: id
      }).then((res) => {
        this.patrolList = res.data;
        // if(id==undefined) {
          this.patrolId = res.data[0].id
          this.patrolName = res.data[0].patrolName
          this.loadListData(this.patrolId)
          this.loadPatrolDetail(this.patrolId)
        // }
      });
    },
    // 获取巡视点详情
    loadPatrolDetail(id) {
      this.$ajax.post("patrolDetail", {
        id: id,
      }).then((res) => {
        this.patrolNumber = res.data.patrolNumber
      });
    },
    facilityClick(data) {
      this.loadPatrolList(data.id)
      this.facilityId = data.id
      this.facilityName = data.name
    },
    patrolClick(data) {
      this.loadListData(data.id)
      this.loadPatrolDetail(data.id)
      this.patrolId = data.id
      this.patrolName = data.patrolName
    },
    // 获取巡视点设备列表
    loadListData(id) {
      this.$ajax.post("patrolDeviceList", {
        facilityId: this.facilityId,
        pageNum: 0,
        pageSize: 0,
        patrolId: id
      }).then((res) => {
        this.deviceTableData = res.data;
        this.tableDataCp = []
        this.deviceTableData.forEach(item => {
          let d = JSON.parse(JSON.stringify(item));
          d.num = 1
          d.checked = false
          this.tableDataCp.push(d)
        })
      });
    },
    // 选择巡检员
    addStaff() {
      this.loadTableData(this.treeData[0].id)
      this.staffListID = []
      this.staffList.forEach(item => {
        this.staffListID.push(item.id)
      })
      this.checkedStaffList = [].concat(this.staffList)
      console.log(this.checkedStaffList)
      this.drawer = true
    },
    // 获取部门结构树
    loadTreeData() {
      this.$ajax.post('departmentResponsibleChild').then(res => {
        this.treeData = res.data
      })
    },
    // 获取部门人员
    loadTableData(id) {
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        res.data.forEach(item => {
            let inIs = this.staffListID.find(function(obj) {
              return obj == item.id
            })
            if (!inIs) {
              item.check = false
            }else {
              item.check = true
            }
          })
        this.tableData = res.data
      })
    },
    // 选择部门结构
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    // 勾选巡检员
    checked(row) {
      if (!this.staffListID.includes(row.id)) {
        this.checkedStaffList.push(row)
        this.staffListID.push(row.id)
      } else {
        this.checkedStaffList.forEach((item, index) => {
          console.log(item)
          if (item.id == row.id) {
            if(item.reconditionStaffId) {
              this.deleteReconditionStaffIds.push(item.reconditionStaffId)
            }
            this.checkedStaffList.splice(index, 1)
            this.$delete(this.staffListID, index)
            console.log(this.staffListID)
          }
        })
      }
    },
    // 选择巡检员点击确定
    drawerOK() {
      this.drawer = false
      this.staffList = [].concat(this.checkedStaffList)
      this.staffListID = [].concat(this.staffListID)
    },
    // 获取检修详情
    loadOverhaulDetail() {
      this.loading = true
      this.$ajax.post("reconditionDynamicDetail", {
        reconditionId: this.row.reconditionId
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        this.form.aliasObj = res.data.patrolDeviceInfo
        this.form.name = res.data.baseInfo.order
        this.form.source = res.data.baseInfo.source==0 ? '新创建' : res.data.baseInfo.source==1 ? '巡检' : '点检' 
        
        res.data.elementInfos[0].staffInfos.forEach((v)=>{
           this.staffList.push({
             id: v.staffId,
             name: v.staffName,
             reconditionStaffId: v.reconditionStaffId
           })
        })

        this.form.desc = res.data.elementInfos[0].description
        this.form.plan = res.data.elementInfos[0].plan
        this.form.eid = res.data.elementInfos[0].eid
        this.cForm = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    // 提交任务
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let fObj =  this.form.aliasObj
          let formData = {
            addDeviceInfo: {},
            deleteReconditionDeviceId: null,
            editElementInfo: {
              addStaffInfos: [],
              deleteReconditionStaffIds: [],
              description: null,
              eid: 0,
              plan: null
            },
            order: null,
            reconditionId: null
          }
          formData.order = this.form.name
          formData.reconditionId = this.row.reconditionId
          formData.editElementInfo.description = this.form.desc
          formData.editElementInfo.plan = this.form.plan
          formData.editElementInfo.eid = this.form.eid
          // 新增设备
          if(fObj.archivesId) {
            formData.addDeviceInfo = {
              alias: fObj.alias,
              applyTime: fObj.applyTime,
              departmentId: fObj.departmentId,
              departmentName: fObj.departmentName,
              archivesId: fObj.archivesId,
              deviceName: fObj.deviceName,
              deviceTypeId: fObj.deviceTypeId,
              deviceTypeName: fObj.deviceTypeName,
              deviceStatus: fObj.deviceStatus,
              ein: fObj.ein,
              facilityId: fObj.facilityId,
              facilityName: fObj.facilityName,
              patrolDeviceId: fObj.patrolDeviceId,
              patrolId: fObj.patrolId,
              patrolName: fObj.patrolName,
              patrolNumber: fObj.patrolNumber,
              specification: fObj.specification
            }
          }
          // 删除设备
          formData.deleteReconditionDeviceId = this.deleteDeviceId
          // 新增人员
          this.staffList.forEach(v=>{
            if(!v.reconditionStaffId) {
              formData.editElementInfo.addStaffInfos.push({
                staffId: v.id,
                staffName: v.name
              })
            }
          })
          // 删除人员
          formData.editElementInfo.deleteReconditionStaffIds = this.deleteReconditionStaffIds 

          if (!fObj.alias) {
            this.$message.error("设备信息不能为空！");
            return
          }
          if (this.staffList.length == 0) {
            this.$message.error("人员配置不能为空！");
            return
          }
          this.loading = true
          this.$ajax.post("reconditionUpdate", formData).then((res) => {
            this.loading = false
            this.$message.success("成功");
            this.$router.push("/overhaulTask");
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/overhaulTask");
      return;
    }
    this.row = this.$route.params.row;
    this.load_facility()
    this.loadTreeData()
    this.loadOverhaulDetail()
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1000px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 390px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #e7e9ee;
}
.checkbox {
  margin-bottom: 20px;
  padding-left: 40px;
}
.form-device-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>